import { useCallback, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { EmptyState } from 'dodoc-design-system';
import { ColumnProps } from 'dodoc-design-system/build/types/Components/Table/Table';

import { useDispatch, useEffectOnUpdate, useSelector } from '_common/hooks';
import { SharedService } from '_common/services';
import { setCurrentAppPage } from 'App/redux/appSlice';
import { navigateToEditor, navigateToMyFiles, navigateToObject } from 'router/history';

import { listObjects } from '_common/components/Table/TableSlice';
import { toggleInfoPanel } from 'Shared/redux/SharedListPageSlice';
import { setActiveTab, setTriggerPage } from 'Search/redux/SearchPageSlice';
import { useFilterSelector } from '_common/components/Filters/FilterSlice';

import {
  InformationPanel,
  ErrorView,
  IntlErrorBoundary,
  EmptyFilteredState,
  FilterDisplay,
  UsernameLabel,
  ObjectPreview,
  TableColumnsToggle,
} from '_common/components';
import { ThunksTable } from '_common/components/Table';
import Cell, { objectIsOverdue } from '_common/components/Table/Cells';
import { TableProps } from '_common/components/Table/TableContent';
import Footer from '_common/components/Table/Footer/Footer';
import SelectionCounter from '_common/components/Table/Footer/SelectionCounter';

import Header from './Header/Header';

import styles from './SharedListPage.module.scss';
import { setObjectPreview } from '_common/components/ObjectPreview/ObjectPreviewSlice';
import { useGetUserSettingsQuery } from '_common/services/api/UserApi';
import { useGetMetadataListQuery } from 'App/redux/MetadataApi';

const PAGE_IDENTITY = 'shared';

const SharedListPage = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { selectFilterParams } = useFilterSelector();

  const data = useSelector((state) => state.app.data);
  const list = useSelector((state) => state.table.identity.shared.list);
  const appLoading = useSelector((state) => state.app.loading.isOpen);
  const infoPanelOpen = useSelector((state) => state.shared.infoPanelOpen);
  const tableListLength = useSelector((state) => state.table.identity.shared.list.length);
  const error = useSelector((state) => state.app.error);
  const hasError = useMemo(
    () => error.status === 400 || error.status === 403 || error.status === 404,
    [error.status],
  );
  const { data: userSettings } = useGetUserSettingsQuery();
  const { data: metadata } = useGetMetadataListQuery(undefined, {
    selectFromResult: (result) => ({ ...result, data: result.data ?? {} }),
  });

  const veevaDocNumberId = Object.keys(metadata).find(
    (key) => metadata?.[key]?.trigger === 'veeva_doc_number',
  );
  const veevaGlobalId = Object.keys(metadata).find(
    (key) => metadata?.[key]?.trigger === 'veeva_global_id',
  );

  const filterParams = useSelector((state) => selectFilterParams(state, state.filters.shared));
  const filters = useMemo(() => {
    return {
      ...JSON.parse(localStorage.getItem(PAGE_IDENTITY) ?? '{}'),
      ...filterParams,
    };
  }, [filterParams]);

  //#region Table object listing handlers
  const fetchObjects = useCallback(async (parameters: any) => {
    const resp = await new SharedService().listShared(parameters);
    return resp;
  }, []);

  useEffect(() => {
    dispatch(
      listObjects({
        identity: PAGE_IDENTITY,
        fetch: fetchObjects,
        cause: 'INITIAL',
        request: { offset: 0 },
      }),
    );
  }, []);

  useEffectOnUpdate(() => {
    dispatch(
      listObjects({
        identity: PAGE_IDENTITY,
        fetch: fetchObjects,
        cause: 'FILTER',
        request: { offset: 0, ...filters },
      }),
    );
  }, [filters]);

  //#endregion

  useEffect(() => {
    dispatch(setCurrentAppPage('/shared'));
    dispatch(setTriggerPage('shared'));
    dispatch(setActiveTab('shared'));
  }, []);

  const columns = useMemo<ColumnProps[]>(() => {
    const columns = [
      {
        id: 'name',
        header: intl.formatMessage({ id: 'storage.browserHeader.name' }),
        orderable: true,
        width: 30 * 8,
        flex: true,
      },

      {
        id: 'due_date',
        width: 48,
      },

      {
        id: 'status',
        header: intl.formatMessage({ id: 'storage.browserHeader.status' }),
        width: 120,
      },

      {
        id: 'time.modification',
        header: intl.formatMessage({ id: 'storage.browserHeader.dateModified' }),
        orderable: true,
        width: 160,
        body: (data: any) => {
          return data['time.modification'];
        },
      },

      {
        id: 'shared_with',
        header: intl.formatMessage({ id: 'storage.browserHeader.sharedWith' }),
        width: 160,
        orderable: true,
      },

      {
        id: 'owner',
        header: intl.formatMessage({ id: 'global.owner' }),
        width: 200,
      },

      {
        id: 'time.creation',
        header: intl.formatMessage({ id: 'CREATION_DATE' }),
        width: 160,
        orderable: true,
        body: (data: any) => {
          return data['time.creation'];
        },
      },

      {
        id: 'metadata.veeva_doc_number',
        header: intl.formatMessage({ id: 'VEEVA_DOC_NUMBER' }),
        width: 160,
        orderable: true,
        body: (data: any) => {
          return data['metadata.veeva_doc_number'];
        },
      },

      {
        id: 'metadata.veeva_global_id',
        header: intl.formatMessage({ id: 'VEEVA_GLOBAL_ID' }),
        width: 160,
        orderable: true,
        body: (data: any) => {
          return data['metadata.veeva_global_id'];
        },
      },
    ] as const;
    return columns.filter((column) => userSettings?.tables.explorer[column.id]);
  }, [userSettings]);

  const value = useMemo(() => {
    return list?.map((objectId) => {
      const object = data[objectId];
      const isProcessing = object.status === 'processing';
      return {
        id: object.id,
        hasError: objectIsOverdue(object),
        name: (
          <Cell testId={`${objectId}-name-column`} ellipsis>
            <Cell.ObjectName object={object} testId={`${objectId}-object-name`} />
          </Cell>
        ),
        dueDate: (
          <Cell testId={`${objectId}-due-date-column`} disabled={isProcessing}>
            <Cell.ObjectDueDate object={object} testId={`${objectId}-object-due-date`} />
          </Cell>
        ),
        status: (
          <Cell testId={`${objectId}-status-column`} disabled={isProcessing}>
            <Cell.ObjectStatus object={object} testId="object-status" />
          </Cell>
        ),
        'time.modification': (
          <Cell testId={`${objectId}-date-modified-column`} disabled={isProcessing}>
            <Cell.DateTime
              date={{ date: object.time?.modification }}
              time={{ time: object.time?.modification }}
            />
          </Cell>
        ),
        shared_with: (
          <Cell testId={`${objectId}-shared-column`} disabled={isProcessing}>
            <Cell.Collaborators object={object} />
          </Cell>
        ),
        owner: (
          <Cell testId={`${objectId}-owner-column`} ellipsis disabled={isProcessing}>
            <UsernameLabel userId={object.owner} />
          </Cell>
        ),
        'time.creation': (
          <Cell testId={`${objectId}-creation-date-column`} disabled={isProcessing}>
            <Cell.DateTime
              date={{ date: object.time?.creation }}
              time={{ time: object.time?.creation }}
            />
          </Cell>
        ),
        'metadata.veeva_doc_number': (
          <Cell testId={`${objectId}-veeva-doc-number`} disabled={isProcessing}>
            {veevaDocNumberId ? object?.metadata?.[veevaDocNumberId] : null}
          </Cell>
        ),
        'metadata.veeva_global_id': (
          <Cell testId={`${objectId}-veeva-global-id`} disabled={isProcessing}>
            {veevaGlobalId ? object?.metadata?.[veevaGlobalId] : null}
          </Cell>
        ),
      };
    });
  }, [list, data]);

  const handleCloseInfoPanel = () => {
    dispatch(toggleInfoPanel());
  };

  // --------------------------------------------------------
  // ----------------------- Actions ------------------------

  const handleRowDoubleClick: TableProps<{ id: string }[]>['onRowDoubleClick'] = ({
    data: object,
    originalEvent,
  }) => {
    const currentObject = data[object.id];

    if (currentObject.status === 'processing' || currentObject.status === 'broken') {
      return;
    }

    switch (currentObject.type) {
      case 'document':
        navigateToEditor(currentObject.id);
        break;
      case 'file':
        dispatch(setObjectPreview({ id: currentObject.id }));
        originalEvent.preventDefault();
        break;
      case 'folder':
        navigateToObject('folder', currentObject.id);
        break;
      case 'dopdf':
        window.open(`/pdf/${currentObject.id}`, '_blank');
        break;
      case 'presentation':
        window.open(`/presentation/${currentObject.id}`, '_blank');
        break;
      default:
        break;
    }
  };

  /**
   * render empty view
   */
  const renderEmptyState = () => {
    if (!appLoading) {
      return (
        <div className={styles.emptyView}>
          <EmptyState
            size="large"
            icon="NoSharedFolders"
            title={intl.formatMessage({ id: 'shared.emptyView.title' })}
            testId="shared-page-empty-list"
          >
            {intl.formatMessage({ id: 'shared.emptyView.subtitle' })}
          </EmptyState>
        </div>
      );
    }
    return null;
  };

  /**
   * render content view
   */
  const renderContentView = () => {
    // check for forbidden view
    if (hasError) {
      return <ErrorView error={error} onClick={navigateToMyFiles} />;
    }

    //If has filters and no matching objects to display
    if (filterParams.filter_fields.length > 0 && tableListLength === 0) {
      return <EmptyFilteredState identity="shared" />;
    }

    return (
      <div className={styles.content}>
        <ThunksTable
          identity={PAGE_IDENTITY}
          columns={columns}
          value={value}
          lazyLoading
          loadingLabel={intl.formatMessage({ id: 'LOADING_ELEMENTS' })}
          renderFooter={() => (
            <Footer>
              <SelectionCounter identity={PAGE_IDENTITY} />
            </Footer>
          )}
          renderEmptyState={renderEmptyState}
          fetchObjects={fetchObjects}
          onRowDoubleClick={handleRowDoubleClick}
          testId="shared-page"
        />
        <InformationPanel
          isOpen={infoPanelOpen}
          onClose={handleCloseInfoPanel}
          testId="shared-information-panel"
        />
      </div>
    );
  };

  return (
    <>
      <div className={styles.root}>
        <div className={styles.header}>
          <IntlErrorBoundary size="small" margin="0 0 0 7rem">
            <Header hasError={hasError} />
          </IntlErrorBoundary>
        </div>
        <div className={styles.headerFooter}>
          <div style={{ flex: 1 }}>
            <FilterDisplay identity="shared" margin={'0 0 0 6rem'} />
          </div>
          <div style={{ display: 'flex', justifyContent: 'flex-end', userSelect: 'none' }}>
            <TableColumnsToggle table="explorer" />
          </div>
        </div>
        {renderContentView()}
        <ObjectPreview />
      </div>
    </>
  );
};

export default SharedListPage;
