export const getAppLocation = (location: Location): Common.Product | 'explorer' => {
  const locationData = location?.pathname.split('/');

  const route = locationData?.[1] || null;

  switch (route) {
    case 'editor': {
      return 'editor';
    }
    case 'pdf': {
      return 'pdf';
    }
    case 'presentation': {
      return 'presentation';
    }
    default: {
      return 'explorer';
    }
  }
};
