import { useOnboardingPresentationActive } from '_common/hooks/Onboarding';
import { GHOST_USERS } from '_common/services/api/consts';
import { useSuiteObject } from '_common/suite';

const usePresentationCollaborators = () => {
  const object = useSuiteObject();

  const onboardingPresentationIsActive = useOnboardingPresentationActive();
  if (object) {
    const collaborators: string[] = onboardingPresentationIsActive
      ? [GHOST_USERS.davidBean['id']]
      : [...object.owners, ...(object.shared_with ?? [])];

    return collaborators;
  }

  return [];
};

export default usePresentationCollaborators;
