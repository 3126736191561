import { MouseEventHandler, useEffect, useMemo, useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { detectOverflow } from '@popperjs/core';
import { useLocation } from 'react-router-dom';
import { Tooltip, usePopper, Popover, Toggle } from 'dodoc-design-system';
import type { PopperProps } from 'dodoc-design-system/build/types/Components/Popper/Popper';

import { useDispatch, useSelector } from '_common/hooks';
import { navigateToTenantSettings } from 'router/history';

import { setNotificationPopoverState } from '_common/components/Popovers/NotificationsPopover/NotificationsSlice';
import { selectUserIsAdmin } from '_common/services/api/authority';

import NotificationsPopover from '_common/components/Popovers/NotificationsPopover/NotificationsPopover';
import UserAvatar from '_common/components/UserAvatar/UserAvatar';
import UserPopover from '_common/components/Popovers/UserPopover/UserPopover';
import SupportPopover from './SupportPopover/SupportPopover';

import styles from './Options.module.scss';
import { InteractionController } from '_common/components';
import { completeAction } from 'App/redux/onboardingSlice';

const Options = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const location = useLocation();

  const [popperHeight, setPopperHeight] = useState(640);

  //@ts-expect-error check Popper 'modifiers' prop type
  const modifiers: PopperProps['modifiers'] = useMemo(() => {
    return [
      {
        name: 'resizePopper',
        enabled: true,
        phase: 'beforeWrite',
        requiresIfExists: ['offset'],
        fn({ state }) {
          const padding = 40; //px
          const maxHeight = 640; //px

          const overflow = detectOverflow(state, {
            padding: { top: padding, bottom: padding },
          });

          const delta = overflow.top - (state.modifiersData.preventOverflow?.y || 0);
          const reajust = padding - delta;
          const currentHeight = state.rects.popper.height;
          let newHeight = 0;

          if (overflow.top > 0) {
            newHeight = state.rects.popper.height + padding - overflow.top;

            if (newHeight > maxHeight) {
              newHeight = maxHeight;
            }

            state.styles.popper.height = `${newHeight}px`;

            setPopperHeight(newHeight);
          } else {
            newHeight = state.rects.popper.height + padding + Math.abs(overflow.top);

            if (newHeight > maxHeight) {
              newHeight = maxHeight;
            }

            state.styles.popper.height = `${newHeight}px`;

            setPopperHeight(newHeight);
          }

          if (reajust > 0) {
            state.styles.popper.transform = `translate(${
              state.modifiersData.popperOffsets?.x
            }px, ${Math.abs(
              newHeight - currentHeight - (state.modifiersData.popperOffsets?.y ?? 0),
            )}px)`;
          }
        },
        effect(args) {
          Promise.resolve().then(args.instance.forceUpdate); //force update to update popper position when open popover
        },
      },
    ];
  }, [setPopperHeight]);

  const notificationsPopover = usePopper({
    placement: 'right-end',
    distance: 16,
    skidding: -40,
    strategy: 'fixed',
    modifiers,
  });

  notificationsPopover.popperProps.style = {
    ...notificationsPopover.popperProps.style,
    marginTop: '5rem',
    marginBottom: '5rem',
  };

  const supportPopover = usePopper({ placement: 'right-end' });
  const userPopover = usePopper({ placement: 'right-end', distance: 16 });

  const appLoading = useSelector((state) => state.app.loading.isOpen);
  const userId = useSelector((state) => state.auth.userId);
  const expanded = useSelector((state) => state.sidebar.expanded);
  const currentPage = useSelector((state) => state.app.currentPage);
  const unreadNotifications = useSelector((state) => state.notifications.unreadCount);
  const currentUserIdAdmin = useSelector(selectUserIsAdmin);

  useEffect(() => {
    const hash = location.hash.replace('#', '');
    if (hash === 'open-notifications') {
      notificationsPopover.open();
      //Clear url parameters
      window.history.pushState(null, '', location.pathname);
    }
  }, [location]);

  useEffect(() => {
    dispatch(setNotificationPopoverState(notificationsPopover.isOpen));
  }, [notificationsPopover.isOpen]);

  const handleNavigateToTenantSettings: MouseEventHandler<HTMLSpanElement> = () => {
    if (location.pathname !== '/settings') {
      navigateToTenantSettings();
      dispatch(completeAction('explorer_tenant_settings_click'));
    }
  };

  return (
    <div className={styles.root}>
      <ul className={styles.menu}>
        <li className={styles.menuItemContainer}>
          <Tooltip
            disabled={expanded}
            content={intl.formatMessage({ id: 'NOTIFICATIONS' })}
            placement="right"
            testId="sidebar-notifications-tooltip"
          >
            <Toggle
              testId="sidebar-notifications"
              variant="monochrome"
              size="large"
              icon="NotificationsBlue"
              loading={appLoading}
              expanded={expanded}
              iconBadge={
                unreadNotifications > 0
                  ? {
                      icon: 'Badge',
                      size: 16,
                      number: unreadNotifications,
                      position: 'top-right',
                    }
                  : undefined
              }
              isToggled={notificationsPopover.isOpen}
              margin="0 0 1rem 0"
              {...notificationsPopover.referenceProps}
            >
              <FormattedMessage id="NOTIFICATIONS" />
            </Toggle>
          </Tooltip>
          <Popover {...notificationsPopover.popperProps} testId="notifications-option-popper">
            <NotificationsPopover
              closePopover={notificationsPopover.close}
              popperHeight={popperHeight}
            />
          </Popover>
        </li>
        {currentUserIdAdmin && (
          <li className={styles.menuItemContainer}>
            <Tooltip
              disabled={expanded}
              content={intl.formatMessage({ id: 'global.settings' })}
              placement="right"
              testId="sidebar-settings-tooltip"
            >
              <InteractionController
                environment="explorer"
                rules={[{ interaction: 'explorer_tenant_settings' }]}
              >
                <Toggle
                  testId="sidebar-settings"
                  variant="monochrome"
                  size="large"
                  icon="SidebarSettings"
                  onClick={handleNavigateToTenantSettings}
                  loading={appLoading}
                  expanded={expanded}
                  isToggled={currentPage === '/settings'}
                  margin="0 0 1rem 0"
                >
                  <FormattedMessage id="global.settings" />
                </Toggle>
              </InteractionController>
            </Tooltip>
          </li>
        )}
        <li className={styles.menuItemContainer} id="SupportPopoverToggle">
          <Tooltip
            disabled={expanded}
            content={intl.formatMessage({ id: 'storage.sidebar.help' })}
            placement="right"
            testId="sidebar-help-tooltip"
          >
            <Toggle
              testId="sidebar-help"
              variant="monochrome"
              size="large"
              icon="Help"
              loading={appLoading}
              expanded={expanded}
              isToggled={supportPopover.isOpen}
              margin="0 0 1rem 0"
              {...supportPopover.referenceProps}
            >
              <FormattedMessage id="storage.sidebar.help" />
            </Toggle>
          </Tooltip>
          <Popover {...supportPopover.popperProps} testId="help-option-popper">
            <SupportPopover close={supportPopover.close} />
          </Popover>
        </li>
        <li className={styles.menuItemContainer} id="UserPopoverToggle">
          <Tooltip
            disabled={expanded}
            content={intl.formatMessage({ id: 'global.profile' })}
            placement="right"
            testId="sidebar-profile-tooltip"
          >
            <Toggle
              testId="sidebar-profile"
              variant="monochrome"
              size="large"
              loading={appLoading}
              expanded // Always expanded (DS Toggle.Monochrome only displays icon when not expanded)
              isToggled={userPopover.isOpen}
              avatar
              {...userPopover.referenceProps}
            >
              <span className={styles.profileToggle}>
                <UserAvatar size="small" userId={userId} margin={expanded ? '0 1rem 0 0' : '0'} />
                {expanded && <FormattedMessage id="global.profile" />}
              </span>
            </Toggle>
          </Tooltip>
          <Popover {...userPopover.popperProps} testId="user-profile-option-popper">
            <UserPopover toggle={userPopover.close} />
          </Popover>
        </li>
      </ul>
    </div>
  );
};

export default Options;
