import { CSSProperties, ReactNode, useEffect, useRef, useState } from 'react';
import { CellMeasurerChildProps } from 'react-virtualized/dist/es/CellMeasurer';

import { usePrimitiveUpdate } from '_common/hooks';

type DynamicCardType = Pick<CellMeasurerChildProps, 'measure'>;

const DynamicCard = ({
  children,
  style,
  measure,
  registerChild,
}: {
  children: ReactNode;
  style: CSSProperties;
} & CellMeasurerChildProps &
  DynamicCardType) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const [cardHeight, setCardHeight] = useState(0);

  usePrimitiveUpdate(() => measure(), cardHeight, 0);

  useEffect(() => {
    if (!containerRef.current) return;
    const resizeObserver = new ResizeObserver(() => {
      setCardHeight(containerRef.current?.offsetHeight ?? 0);
    });
    resizeObserver.observe(containerRef.current);
    return () => resizeObserver.disconnect(); // clean up
  }, []);

  return (
    <div
      ref={(el) => registerChild && registerChild(el as Element)}
      style={{ ...style, padding: '0.5rem 1rem' }}
    >
      <div ref={containerRef}>{children}</div>
    </div>
  );
};

export default DynamicCard;
