import { getAppLocation } from '_common/utils/getAppLocation';
import type { OnboardingEnvironments } from 'App/redux/onboardingSlice';

/**
 * Returns if the onboarding is active and the user is in the onboarding enviroment
 */
export const isOnboardingActive = (
  state: RootState['onboarding'],
  enviroment: OnboardingEnvironments,
) => {
  const active = state.active[enviroment];
  const started = state.started[enviroment];
  const location = window.location;
  const appLocation = getAppLocation(location);

  let locationEnviroment: OnboardingEnvironments;

  /**
   * Parse location to OnboardingEnviroment
   */
  switch (appLocation) {
    case 'pdf': {
      locationEnviroment = 'dopdf';
      break;
    }
    default: {
      locationEnviroment = appLocation;
      break;
    }
  }

  return locationEnviroment === enviroment && (active || started);
};

export const isOnboardingExplorerActive = (state: RootState) => {
  return isOnboardingActive(state.onboarding, 'explorer');
};
export const isOnboardingEditorActive = (state: RootState) => {
  return isOnboardingActive(state.onboarding, 'editor');
};
export const isOnboardingPDFActive = (state: RootState) => {
  return isOnboardingActive(state.onboarding, 'dopdf');
};
export const isOnboardingPresentationActive = (state: RootState) => {
  return isOnboardingActive(state.onboarding, 'presentation');
};
